<template>
<div class="about">
    <h5>KharaMe support both RMX1971(Realme 5 Pro, Realme Q) and r5x(Realme 5, Realme 5i, Realme 5s).</h5>
    <h6>We are a bunch enthusiasts supporting RMX1971, r5x with all the full featured custom roms, kernels & recoveries</h6>
    <h6>Join Us :<v-btn class="ma-2" @click="rmx1971()" style="color:#263AA2">RMX1971</v-btn><v-btn class="ma-2" @click="r5x()" style="color:#263AA2">r5x</v-btn></h6>
    <h6>Our Github sources: <v-btn class="ma-2" @click="github()" style="color:#263AA2">KharaMe</v-btn></h6>
</div>
</template>

<script>  
  export default {
    name: 'About',
    components: {

    },
    data: () => ({
    }),

    methods: {
        rmx1971() {
            window.open("https://t.me/Realme5Pro_Global");
        },
        r5x() {
            window.open("https://t.me/Realme5Dev")
        },
        github() {
            window.open("https://github.com/KharaMe-devices")
        }
    },
  }
</script>
<style scoped>
.about {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    color: white;
}
.about h5 {
    margin-bottom: 2%;
}
.about h6 {
    margin-bottom: 2%;
}
@media (max-width: 550px) {
    .about {
        margin-top: 4%;
        width: 80%;
    }
    .about h5 {
        margin-bottom: 8%;
    }
    .about h6 {
        margin-bottom: 8%;
    }
}
</style>